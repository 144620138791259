import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import { ComplainTypeField } from '@/modules/complain-type/complain-type-field';
import EmailField from '@/shared/fields/email-field';
import EnumField from '@/shared/fields/enum-field';
import DateField from "@/shared/fields/date-field";
import BooleanField from "@/shared/fields/boolean-field";
import { TerritorialAdministrativeUnitField } from '@/shared/common-fields/territorial-administrative-unit-field';

const label = (name) => i18n.t(`COMPLAIN.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`COMPLAIN.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);
const placeholder = (name) => i18n.t(`COMPLAIN.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    identification_number: new IdField('identification_number', label('identification_number')),
    complain_type_id: ComplainTypeField.relationToOne(
        'complain_type_id',
        label('complain_type'),
        { autocompleteRequired: true },
        {},
        [],
        { asyncData: true, hasPagination: true }
    ),
    complain_type_name: new StringField('complain_type_name', label('complain_type')),
    locality_id: new StringField('locality_id', label('locality'), { required: true }),
    locality_name: new StringField('locality_name', label('locality'), { required: true }, { placeholder: placeholder('locality') }),
    address: new StringField('address', label('address'), { required: true }),
    address_details: new StringField('address_details', label('address_details')),
    description: new StringField('description', label('description'), { required: true }, {
        placeholder: placeholder('description')
    }),
    reporting_user_id: new IdField('reporting_user_id'),
    reporting_user_first_name: new StringField('reporting_user_first_name', label('first_name'), { required: true }),
    reporting_user_last_name: new StringField('reporting_user_last_name', label('last_name'), { required: true }),
    reporting_user_full_name: new StringField('reporting_user_full_name', label('reported_by'),
        {}, { placeholder: placeholder('reported_by') }),
    reporting_user_email: new EmailField('reporting_user_email', label('email'), { required: true }),
    reporting_user_phone: new StringField('reporting_user_phone', label('phone'), { required: true }),
    contact_user_first_name: new StringField('contact_user_first_name', label('first_name'), { required: true }),
    contact_user_last_name: new StringField('contact_user_last_name', label('last_name'), { required: true }),
    contact_user_full_name: new StringField('contact_user_full_name', label('reported_for'),
        {}, { placeholder: placeholder('reported_for') }),
    contact_user_email: new EmailField('contact_user_email', label('email'), { required: true }),
    contact_user_phone: new StringField('contact_user_phone', label('phone'), { required: true }),
    status: new EnumField(
        'status',
        label('status'),
        [
            {
                value: 'new',
                name: 'new',
                text: enumLabel('status', 'new'),
                type: 'primary',
            },
            {
                value: 'pending',
                name: 'pending',
                text: enumLabel('status', 'pending'),
                type: 'warning',
            },
            {
                value: 'resolved',
                name: 'resolved',
                text: enumLabel('status', 'resolved'),
                type: 'success',
            },
            {
                value: 'rejected',
                name: 'rejected',
                text: enumLabel('status', 'rejected'),
                type: 'danger',
            },
            {
                value: 'dismissed',
                name: 'dismissed',
                text: enumLabel('status', 'dismissed'),
                type: 'danger',
            },
        ],
        {},
        {
            placeholder: placeholder('status'),
        }
    ),
    created_date: new DateField('created_date', label('created_date')),
    use_profile_data: new BooleanField('use_profile_data', label('use_profile_data')),
    territorial_administrative_unit_id: TerritorialAdministrativeUnitField.relationToOne(
        'territorial_administrative_unit_id',
        label('territorial_administrative_unit_id'),
        { autocompleteRequired: true },
        { placeholder: placeholder('territorial_administrative_unit_id') },
    ),
};

export class ComplainModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
