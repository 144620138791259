<template>
    <st-page
        :title="titleComputed"
        :subtitle="createdDate"
        layout="tab"
        :backLink="backLinkComputed"
    >
        <loader v-if="isLoading"/>
        <template #toolbar>
            <portal-target name="complain-toolbar"></portal-target>
        </template>
        <b-tabs class="st-tabs" justified lazy>
            <b-tab v-for="tab in tabs" :key="tab.index" :disabled="tab.disabled" :title-item-class="navItemClass(tab)">
                <template #title>
                    <div class="d-flex justify-content-center w-100">
                        <i :class="['fas', tab.icon]"></i>
                        <span class="text-uppercase ml-4">{{ $t(tab.titleKey) }}</span>
                    </div>
                </template>
                <component
                    v-if="!isLoading"
                    :is="tab.componentType"
                    :complainId="id"
                >
                </component>
            </b-tab>
        </b-tabs>
    </st-page>
</template>

<script>
import ComplainDetail from "../components/ComplainDetail";
import ComplainDiscussion from "../components/ComplainDiscussion";
import {mapActions, mapGetters} from "vuex";
import { ComplainPermissions } from '@/modules/complain/complain-permissions';
import moment from 'moment';

export default {
    name: 'ComplainForm',
    components: {
      ComplainDetail,
      ComplainDiscussion,
    },
    props: {
        id: String,
    },
    data() {
        return {
            justificationNumber: '',
            tabs: [
                {
                    index: 0,
                    name: 'complain-detail',
                    titleKey: 'COMPLAIN.TABS.COMPLAIN',
                    icon: this.id ? 'fa-check-circle' : 'fa-edit',
                    componentType: 'ComplainDetail',
                    disabled: false,
                },
                {
                    index: 1,
                    name: 'complain-discussion',
                    icon: this.id ? 'fa-check-circle' : 'fa-exclamation-circle',
                    titleKey: 'COMPLAIN.TABS.DISCUSSIONS',
                    componentType: 'ComplainDiscussion',
                    disabled: true,
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            record: 'complain/form/record',
            loading: 'shared/loading',
            currentUser: 'auth/currentUser',
            appConfig: "shared/appConfig",
        }),
        backLinkComputed() {
            return this.id ? { name: 'complain' } : '';
        },
        titleComputed() {
            if (!this.id) return this.$t('COMPLAIN.FORM.TITLE');
            if (!this.record) return '';
            const uid = this.record?.identification_number ?? '';
            const complainTypeName = this.record?.complain_type_name;
            const title = `${uid} - ${complainTypeName}`;
            return title;
        },
        loadingFind() {
            return this.loading['complain/find'];
        },
        isLoading() {
            return this.loadingFind;
        },
        hasPermissionToStaffComplains() {
            return new ComplainPermissions(this.currentUser).readStaff;
        },
        createdDate() {
            if (!this.id || !this.record?.created_date) return '';
            return moment(new Date(this.record.created_date)).format(this.appConfig.DATE_FORMAT);
        }
    },
    methods: {
        ...mapActions({
            doFind: 'complain/form/find',
            doFindStaff: 'complain/form/findForStaff',
            doClear: 'complain/form/clear',
        }),
        navItemClass(tab) {
            if (tab?.disabled) return '';
            if (this.id) return 'nav-item--success';
            return 'nav-item--warning';
        },
    },
    async created() {
        if (this.id) {
            if (this.hasPermissionToStaffComplains) {
                await this.doFindStaff(this.id);
            } else {
                await this.doFind(this.id);
            }
        } else {
            await this.doClear();
        }
    }
}
</script>
